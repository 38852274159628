import styled from 'styled-components';
import { border, typography, space, color, layout, position, flexbox, grid, system, background } from 'styled-system';

const Box = styled('div')(
  system({
    transform: true,
    whiteSpace: true,
    gridColumnEnd: true,
    gridColumnStart: true,
    rowGap: true,
    columnGap: true,
    transformOrigin: true,
    textTransform: true,
    textOverflow: true,
    cursor: true,
    boxShadow: true,
    pointerEvents: true,
    gap: true,
    transition: true
  }),
  border,
  typography,
  space,
  layout,
  color,
  position,
  flexbox,
  grid,
  background
);

export default Box;
