import React, { useState } from 'react';
import styled from 'styled-components';

const StyledContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '40vw',
  maxWidth: '1174px',
  maxHeight: '469px',
  overflow: 'hidden',
  margin: 'auto',
  borderRadius: theme.radii[2]
}));

const StyledFigure = styled('figure')({
  backgroundImage: 'url("/assets/homepage/company-screenshot@2x.webp"), url("/assets/homepage/company-screenshot@2x.png")',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'left center',
  position: 'relative',
  fontSize: 0,
  width: '100%',
  height: '100%',
  margin: 0,
  '> img': {
    position: 'relative',
    width: '100%'
  }
});

const StyledDivisor = styled('div')(({ position }) => ({
  backgroundImage: 'url("/assets/homepage/freelance-screenshot@2x.webp"), url("/assets/homepage/freelance-screenshot@2x.png")',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'left center',
  position: 'absolute',
  boxShadow: '0 5px 10px -2px rgba(0,0,0,0.3)',
  overflow: 'hidden',
  bottom: 0,
  width: `${position}%`,
  height: '100%'
}));

const StyledRange = styled('input')({
  appearance: 'none',
  position: 'relative',
  top: '-2rem',
  left: '-2%',
  backgroundColor: 'rgba(0,0,0,0.15)',
  width: '104%',
  '&:focus, &:active': {
    outline: 'none'
  },
  '&:-moz-range-track': {
    appearance: 'none',
    height: '15px',
    width: '98%',
    backgroundColor: 'rgba(0,0,0,0.15)',
    position: 'relative',
    outline: 'none'
  },
  '&:active': {
    border: 'none',
    outline: 'none'
  },
  '&::-webkit-slider-thumb': {
    '-webkit-appearance': 'none',
    width: '16px',
    height: '16px',
    background: '#15803D',
    borderRadius: 15,
    boxShadow: '0 3px 8px rgba(0,0,0,.2)'
  },
  '&::-moz-range-thumb': {
    '-moz-appearance': 'none',
    width: '16px',
    height: '16px',
    background: '#15803D',
    borderRadius:15,
    boxShadow: '0 3px 8px rgba(0,0,0,.2)'
  },
  '&:focus::-webkit-slider-thumb, &:focus::-moz-range-thumb': {
    background: 'rgba(255,255,255,0.5)'
  }
});

const PreviewSlider = () => {
  const [divisorValue, setDivisorValue] = useState(50);
  return (
    <StyledContainer>
      <StyledFigure>
        <StyledDivisor position={divisorValue} />
      </StyledFigure>
      <StyledRange type="range" min="0" max="100" id="slider" onInput={e => setDivisorValue(e.target.value)} />
    </StyledContainer>
  );
};

export default PreviewSlider;
