import React from 'react';
import styled from 'styled-components';
import { array } from 'prop-types';
import { assetize } from '../../utils/pureUtils';
import { commonRoutes } from '../../routes';
import { useTranslate, translateArray } from '../../translate/I18n';
import BeelanceRoles from './BeelanceRoles';
import BeelanceMissions from './BeelanceMissions';

import Box from '../atoms/Box';
import ButtonGradient from '../atoms/ButtonGradient';
import Img from '../atoms/Img';
import Text from '../atoms/Text';

import Link from '../atoms/Link';
import Testimonials from './Testimonials';
import TrustedByBanner from '../elements/trustedByBanner/TrustedByBanner';
import PreviewSlider from './PreviewSlider';
import ArrowRightCircle from '../atoms/icons/ArrowRightCircle';
import BackToTop from './BackToTop';

const {
  general: { homePageCompany, homePageFreelance }
} = commonRoutes;

const section2ImageNames = ['search-matching', 'medal', 'planet'];

const StyledSection2Illustration = styled('div')(({ theme, index }) => ({
  marginBottom: theme.space[4],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '150px',
  '& img': {
    height: index === 0 || index === 2 ? '89px' : '140px'
  }
}));

const HomePage = ({ missions }) => {
  const { t } = useTranslate();

  return (
    <Box bg="#F3F4F6" pb="120px" position="relative">
      <BackToTop />
      <Box
        textAlign="center"
        py="5%"
        background="linear-gradient(180deg, #FFFFFF 0%, #F3F4F6 100%)"
        mx="auto"
        overflow={{ _: 'hidden', xl: 'visible' }}>
        <Text tag="h1" styleName="wH1" mb={4} maxWidth="1000px" mx="auto" px={{ _: 1, sm: 2, md: 3, lg: 4 }}>
          {t('homePageV2:header:title')}
        </Text>
        <Text fontSize="20px" lineHeight="25px" mb="6" maxWidth="635px" mx="auto">
          {t('homePageV2:header:description')}
        </Text>
        <Box
          display="flex"
          gap="24px"
          flexDirection={{ _: 'column', md: 'row' }}
          justifyContent="center"
          px={{ _: 1, sm: 2, md: 3, lg: 4 }}>
          <ButtonGradient as={Link} styleType={'secondary'} to={homePageCompany.to}>
            {t('homePageV2:header:cta1')}
          </ButtonGradient>
          <ButtonGradient as={Link} styleType={'secondary'} to={homePageFreelance.to}>
            {t('homePageV2:header:cta2')}
          </ButtonGradient>
        </Box>
        <Box position="absolute" left={{ _: '-62px', xl: 0, xxl: '5%' }} top="2%" display={{ _: 'none', lg: 'block' }}>
          <Img
            alt="3D objects illustration"
            src={assetize('/homepage/bg-left.webp')}
            srcRetina={assetize('/homepage/bg-left@2x.webp')}
            fallback={assetize('/homepage/bg-left.png')}
            fallbackRetina={assetize('/homepage/bg-left@2x.png')}
            lazyloading={true}
          />
        </Box>

        <Box position="absolute" right={{ _: '-62px', xl: 0, xxl: '5%' }} top="5%" display={{ _: 'none', lg: 'block' }}>
          <Img
            alt="3D objects illustration"
            src={assetize('/homepage/bg-right.webp')}
            srcRetina={assetize('/homepage/bg-right@2x.webp')}
            fallback={assetize('/homepage/bg-right.png')}
            fallbackRetina={assetize('/homepage/bg-right@2x.png')}
            lazyloading={true}
          />
        </Box>
      </Box>

      <PreviewSlider />

      <Box my="16">
        <Testimonials role="homepage" />
      </Box>

      <Box maxWidth="1240px" mx="auto" px={{ _: 1, sm: 2, md: 3, lg: 4 }} textAlign="center">
        <Text tag="h2" styleName="wH2b" mb="3">
          {t('homePageV2:section2:title')}
        </Text>
        <Text color="#424547" fontSize="16px" fontWeight="500" maxWidth="600px" mx="auto" mb="5">
          {t('homePageV2:section2:description')}
        </Text>
      </Box>
      <Box
        display="grid"
        gridGap="8px"
        gridTemplateColumns={{ _: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }}
        maxWidth="1240px"
        px={{ _: 1, sm: 2, md: 3, lg: 4 }}
        mx="auto"
        mb="98px">
        {translateArray(t, 'homePageV2:section2:items').map(({ title, description }, index) => (
          <Box
            bg="white"
            borderRadius="2"
            key={index}
            display="flex"
            flexDirection="column"
            justifyContent="space-between">
            <Box p={4}>
              <Text as="h4" fontSize="24px" fontWeight="700" mb={3}>
                {title}
              </Text>
              <Text styleName="wPNormal">{description}</Text>
            </Box>
            <StyledSection2Illustration index={index}>
              <Img
                alt={`${section2ImageNames[index]} illustration`}
                src={assetize(`/homepage/${section2ImageNames[index]}.webp`)}
                srcRetina={assetize(`/homepage/${section2ImageNames[index]}@2x.webp`)}
                fallback={assetize(`/homepage/${section2ImageNames[index]}.png`)}
                fallbackRetina={assetize(`/homepage/${section2ImageNames[index]}@2x.png`)}
                lazyloading={true}
              />
            </StyledSection2Illustration>
          </Box>
        ))}
      </Box>

      <Text fontSize="18px" lineHeight="26px" mb="2" fontWeight="600" textAlign="center">
        {t('homePageV2:section1:title')}
      </Text>
      <Box mb="16">
        <TrustedByBanner />
      </Box>

      <Box maxWidth="1240px" mx="auto" px={{ _: 1, sm: 2, md: 3, lg: 4 }} mb={{ _: 8, lg: 20 }}>
        <Text as="h4" styleName="wH2b" fontWeight="700" mb={3}>
          {t('homePageV2:section3:title')}
        </Text>
        <Text color="#424547" fontSize="16px" fontWeight="500" maxWidth="545px" mb="6">
          {t('homePageV2:section3:description')}
        </Text>

        <BeelanceRoles />
      </Box>

      <Box textAlign="center" maxWidth="1240px" mx="auto" px={{ _: 1, sm: 2, md: 3, lg: 4 }}>
        <Text as="h2" styleName="wH2b" fontWeight="700" mb={3} maxWidth="820px" mx="auto">
          {t('homePageV2:footer:title')}
        </Text>
        <Box display="flex" gap="24px" width="100%" flexDirection={{ _: 'column', lg: 'row' }}>
          <Link to={homePageCompany.to} display="block" flex="1">
            <Box
              px="5"
              pt="4"
              pb="2"
              fontSize={{ _: '28px', lg: '40px' }}
              flex="1"
              lineHeight="50px"
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              background="white"
              borderRadius="2"
              position="relative"
              overflow="hidden"
              color="#111517"
              textAlign="left">
              {t('homePageV2:footer:asCompany')}
              <Text color="#424547" fontSize="16px" fontWeight="500" lineHeight="22px" mt="2">
                {t('homePageV2:footer:findFreelance')}
              </Text>
              <Box textAlign="right" mt="2">
                <ArrowRightCircle color="#95979A" />
              </Box>
              <Box position="absolute" bottom="-46px" left="-32px">
                <Img
                  src={assetize('/homepage/waves1.webp')}
                  srcRetina={assetize('/homepage/waves1@2x.webp')}
                  fallback={assetize('/homepage/waves1.png')}
                  fallbackRetina={assetize('/homepage/waves1@2x.png')}
                  alt="section wave decoration"
                  lazyloading={false}
                />
              </Box>
            </Box>
          </Link>

          <Link to={homePageFreelance.to} display="block" flex="1">
            <Box
              px="5"
              pt="4"
              pb="2"
              fontSize={{ _: '28px', lg: '40px' }}
              flex="1"
              lineHeight="50px"
              height="100%"
              display="flex"
              flexDirection="column"
              position="relative"
              overflow="hidden"
              justifyContent="space-between"
              background="white"
              borderRadius="2"
              color="#111517"
              textAlign="left">
              {t('homePageV2:footer:asFreelancer')}
              <Text color="#424547" fontSize="16px" fontWeight="500" lineHeight="22px" mt="2">
                {t('homePageV2:footer:findMission')}
              </Text>
              <Box textAlign="right" mt="2">
                <ArrowRightCircle color="#95979A" />
              </Box>
              <Box position="absolute" bottom="-66px" left="-32px">
                <Img
                  src={assetize('/homepage/waves2.webp')}
                  srcRetina={assetize('/homepage/waves2@2x.webp')}
                  fallback={assetize('/homepage/waves2.png')}
                  fallbackRetina={assetize('/homepage/waves2@2x.png')}
                  alt="section wave decoration"
                  lazyloading={false}
                />
              </Box>
            </Box>
          </Link>
        </Box>
      </Box>

      <Box maxWidth="1240px" mx="auto" px={{ _: 1, sm: 2, md: 3, lg: 4 }} mt={{ _: 6, lg: 20 }}>
        <BeelanceMissions missions={missions} />
      </Box>
    </Box>
  );
};

HomePage.propTypes = {
  missions: array
};

export default HomePage;
