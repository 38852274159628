/* eslint-disable max-lines */
import React from 'react';
import styled from 'styled-components';
import { border, variant, layout, space, color, typography, flexbox, system, shadow, position } from 'styled-system';
import { string, node } from 'prop-types';
import colors from '../../theme/colors';

const mobileBreakpoint = '768px';

const StyledText = styled('div')(
  system({
    textTransform: true,
    whiteSpace: true,
    textOverflow: true,
    cursor: true,
    textDecoration: true
  }),
  () =>
    variant({
      prop: 'styleName',
      variants: textStyles
    }),
  layout,
  space,
  color,
  typography,
  flexbox,
  border,
  shadow,
  position
);

const Text = ({ tag, children, ...props }) => {
  return (
    <StyledText as={tag} {...props}>
      {children}
    </StyledText>
  );
};

Text.propTypes = {
  tag: string,
  styleName: string,
  children: node
};

Text.defaultProps = {
  tag: 'p'
};

export default Text;

const textStyles = {
  wH1: {
    fontSize: '64px',
    fontWeight: 800,
    lineHeight: 1.2,
    [`@media (max-width: ${mobileBreakpoint})`]: {
      fontSize: '28px'
    }
  },
  wH1b: {
    fontSize: '42px',
    fontWeight: 900,
    lineHeight: 1.2,
    [`@media (max-width: ${mobileBreakpoint})`]: {
      fontSize: '28px'
    }
  },
  wH2: {
    fontSize: '32px',
    fontWeight: 900,
    lineHeight: 1.2
  },
  wH2b: {
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '48px',
    [`@media (max-width: ${mobileBreakpoint})`]: {
      fontSize: '24px',
      lineHeight: '28px'
    }
  },
  wH3: {
    fontSize: '20px',
    fontWeight: 800
  },

  wH4: {
    fontSize: '16px',
    fontWeight: 800
  },

  wH5: {
    fontSize: '10px',
    fontWeight: 900
  },

  wPBig: {
    lineHeight: 1.67,
    fontSize: '18px'
  },

  wPNormal: {
    lineHeight: 1.63,
    fontSize: '14px'
  },

  wPBold: {
    lineHeight: 1.63,
    fontSize: '14px',
    fontWeight: 700
  },

  wPSmall: {
    lineHeight: 1.57
  },

  wPTiny: {
    fontSize: '12px',
    fontWeight: 700
  },

  wPLightWeight: {
    fontSize: '12px',
    fontWeight: 600,
    fontHeight: '15px'
  },

  wDecorationText: {
    color: 'rgba(0, 0, 0, 0.06)',
    fontSize: '120px',
    fontWeight: 900
  },

  pLabelPrimary: {
    lineHeight: 1.43,
    fontWeight: 800
  },

  pLabelSecondary: {
    color: colors.greyDark,
    lineHeight: 1.43,
    fontWeight: 800
  },

  wLabelPrimary: {
    color: colors.black,
    fontWeight: 800
  },

  wLabelSecondary: {
    color: colors.grey,

    fontWeight: 800
  },

  wPError: {
    color: colors.red,
    lineHeight: 1.57,
    fontWeight: 700
  },

  wPSuccess: {
    color: colors.blue,
    lineHeight: 1.57,
    fontWeight: 700
  },

  wPSmallBold: {
    lineHeight: 1.57,
    fontWeight: 800
  },

  wLabelBigPrimary: {
    color: colors.black,
    fontSize: '16px',
    fontWeight: 800
  },

  wLabelBigSecondary: {
    color: colors.grey,
    fontSize: '16px',
    fontWeight: 700
  },

  wEnumerationDefault: {
    color: colors.grey,
    fontSize: '20px',
    fontWeight: 700
  },

  wEnumerationActive: {
    color: colors.secondary,
    fontSize: '20px',
    fontWeight: 700
  },

  pPNormal: {
    lineHeight: 1.43
  },

  pPBold: {
    lineHeight: 1.43,
    fontWeight: 700
  },

  pPTiny: {
    lineHeight: 1.67,
    fontSize: '12px',
    fontWeight: 800
  },

  pPTinyLight: {
    color: colors.greyDark,
    lineHeight: 1.67,
    fontSize: '12px',
    fontWeight: 500
  },

  pTextbuttonPrimary: {
    color: colors.main,
    lineHeight: 1.43,
    fontWeight: 800
  },

  pH2: {
    fontSize: '20px',
    lineHeight: '26px',
    fontWeight: 700
  },

  pH3: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 700
  },

  pTextbuttonSecondary: {
    color: colors.secondary,
    lineHeight: 1.43,
    fontWeight: 800
  },

  pTextbuttonTertiary: {
    lineHeight: 1.43,
    fontWeight: 800
  },

  pTextbuttonWarning: {
    color: colors.red,
    lineHeight: 1.43,
    fontWeight: 800
  },

  pTextbuttonDisabled: {
    color: colors.grey,
    lineHeight: 1.43,
    fontWeight: 800
  },

  pLabelSuccess: {
    color: colors.main,
    lineHeight: 1.43,
    fontWeight: 800
  },

  pLabelError: {
    color: colors.red,
    lineHeight: 1.43,
    fontWeight: 800
  },

  pLabelWarning: {
    color: colors.secondary,
    lineHeight: 1.43,
    fontWeight: 800
  },

  pPWarning: {
    color: colors.secondary,
    lineHeight: 1.43
  },

  pPLight: {
    color: colors.greyDark,
    lineHeight: 1.43
  },

  pPBig: {
    lineHeight: 1.38,
    fontSize: '16px'
  },

  pH1: {
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: 800
  },

  mobilewH2: {
    fontSize: '24px',
    fontWeight: 900
  },

  mobilewH3: {
    fontSize: '18px',
    fontWeight: 900
  }
};
