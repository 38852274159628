import React from 'react';
import { useTranslate } from '../../translate/I18n';
import PropTypes from 'prop-types';
import Box from '../atoms/Box';
import Link from '../atoms/Link';
import Text from '../atoms/Text';
import { translateRoute } from '../../routesActions/routeHelper';

const BeelanceMissions = ({ missions }) => {
  const { t, i18n } = useTranslate(['homePage']);

  const missionsToDisplay = missions.slice(0, 60);

  return (
    <Box width="100%" mx="auto" background="white" px={{ _: 2, lg: 4 }} py={{ _: 2, lg: 4 }} borderRadius="12px">
      <Text styleName="wH2" tag="h2" mb={6}>
        {t('homePage:ourLastMissions')}
      </Text>
      <Box
        display="grid"
        width="100%"
        gridTemplateColumns={{ _: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
        columnGap="16px">
        {missionsToDisplay.map(mission => {
          const path = `/missions/${mission.slugWithCompany}`;
          return (
            <Box mb={{ _: 1, md: 2 }} key={mission.slugWithCompany}>
              <Link type="tertiary" fontSize="14px" fontWeight="400" to={translateRoute(path, 'en', i18n.language)}>
                {mission.title}
              </Link>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

BeelanceMissions.propTypes = {
  missions: PropTypes.array
};

export default BeelanceMissions;
